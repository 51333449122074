@tailwind base;
@tailwind components;
@tailwind utilities;

/* @react-pdf-viewer PDF viewer custom css changes */
.rpv-default-layout__body {
  border-radius: 16px;
  border: none;
}

.rpv-default-layout__container {
  border-radius: 16px;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 8px 0px;
  margin-bottom: 8px;
}

.rpv-default-layout__toolbar {
  background-color: white !important;
  border: none !important;
  border-radius: 16px;
  z-index: 0 !important; /* Fix toolbar z-index issue */
}

.rpv-core__page-layer:after {
  box-shadow: none !important;
  border: 0.25px solid #e5e7eb !important;
  border-radius: 8px;
}

.toolbar-border-radius-none .rpv-default-layout__toolbar {
  border-radius: 0 !important;
}

.toolbar-border-radius-none .rpv-default-layout__body {
  border-radius: 0 !important;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}
